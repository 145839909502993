import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'MCM | DEV', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'MCM DEV Simplefolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello There, I\'m',
  name: 'Marcelo Campelo',
  subtitle: 'Software Development Engineer. Solving problems.',
  cta: 'Code: Fast | Reliable | Scalable',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'Computer Engineer',
  paragraphTwo: 'Back-end Dev working with great people.',
  paragraphThree: 'Learning a lot everyday.',
  resume: '', // if no resume, the button will not show up
};	

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.png',
    title: '',
    info: 'Well, I should post a project here before december. Will do. ',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },// a partir daqui eu comentei para ficar fácil incluir projetos depois
//  {
//    id: nanoid(),
//    img: 'project.jpg',
//    title: '',
//    info: '',
//    info2: '',
//    url: '',
//    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
//  },
//  {
//    id: nanoid(),
//    img: 'project.jpg',
//    title: '',
//    info: '',
//    info2: '',
//    url: '',
//    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
//  },
];


// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'mcmarcelocampelo@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/marcelocampelo',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/marcelocampelo/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/marcelocampelo',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
